import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { QueryClientProvider } from 'react-query';
import { ToastContainer } from 'react-toastify';
import { AppRouter } from './routes/AppRouter';
import { queryClient } from './services/react-query/queryClient';
import 'react-toastify/dist/ReactToastify.css';

const App: React.FC = () => (
    <QueryClientProvider client={queryClient}>
        <BrowserRouter>
            <AppRouter />
            <ToastContainer />
        </BrowserRouter>
    </QueryClientProvider>
);

export default App;
