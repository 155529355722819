import { ErrorToast } from "components/src/toast";


export const errorHandlerFn = (err: any) => {
  if (err.response) {
    if (err.response.status === 401) {
      ErrorToast(err.response?.data?.message || "Please login to continue");
      // window.location.href = "/auth/login";
    }
    if (err.response.status === 417) {
      ErrorToast(err.response?.data?.message);
    }
    if (err.response.status === 402 || err.response.status === 405) {
      ErrorToast(err.response?.data?.message || err.response.data.detail);
    }
    if (err.response.status === 400) {
      ErrorToast(err.response?.data?.message);
    }
    if (err.response.status === 404) {
      ErrorToast(err?.response?.data?.title || err?.response?.data?.message);
    }
    if (
      err.response.status === 503 ||
      err.response.status === 501 ||
      err.response.status === 504 ||
      err.response.status === 405 ||
      err.response.status === 500 ||
      err.response.status === 403
    ) {
      ErrorToast(
        err.response?.data?.message ||
          "Error in network connection, try later...",
      );
    } else {
      ErrorToast(err.response?.data?.detail || "Internal server error");
    }
  } else {
    ErrorToast(err.response?.data?.message || "Network Error!");
  }
}
