import type { FormInstance } from 'antd';
import { Button, Form } from 'antd';
import React, { useEffect, useState } from 'react';
import { ButtonType } from 'antd/es/button';
import { isEmpty } from 'utils'; 

const SubmitButton = ({
    form,
    submitProp,
    className,
    otherDisable,
    width,
    type,
    setDisabled,
}: {
    form: FormInstance;
    submitProp?: any;
    className?: any;
    otherDisable?: boolean;
    width?: any;
    type: ButtonType;
    setDisabled?:(e:boolean) => void
}) => {
    const [submittable, setSubmittable] = React.useState(false);

    // Watch all values
    const values = Form.useWatch([], form);

    React.useEffect(() => {
        form.validateFields({ validateOnly: true }).then(
            () => {
                // console.log('Yes');
                setSubmittable(true);
                setDisabled?.(true);
            },
            () => {
                // console.log('No');
                setSubmittable(false);
                setDisabled?.(false);
            },
        );
    }, [values, form, setDisabled]);

    const [windowSize, setWindowSize] = useState<{
        width: number;
        height: number;
    }>({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    const handleResize = () => {
        setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight,
        });
    };

    useEffect(() => {
        setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight,
        });
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <Button
            htmlType="submit"
            type={type ?? 'default'}
            disabled={!submittable || (!isEmpty(otherDisable) && !otherDisable)}
            className={`${className} ${width || (windowSize.width > 540 ? 'w-350px' : 'w-250px')} ${
                !submittable || (!isEmpty(otherDisable) && !otherDisable) ? 'bg-gray-400' : ''
            }`}
        >
            {submitProp || <span>Proceed</span>}
        </Button>
    );
};
export { SubmitButton };

export const CustomButton = ({
    submitProp,
    className,
    disabled,
    onClick,
    width,
    type,
}: {
    submitProp?: any;
    className?: any;
    disabled?: boolean;
    onClick: () => void;
    width?: any;
    type?: ButtonType;
}) => {
    const [windowSize, setWindowSize] = useState<{
        width: number;
        height: number;
    }>({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    const handleResize = () => {
        setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight,
        });
    };

    useEffect(() => {
        setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight,
        });
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <Button
            htmlType="button"
            disabled={disabled}
            className={` d-flex justify-content-center align-items-center ${
                width || (windowSize.width > 540 ? 'w-350px' : 'w-75')
            } ${className} `}
            onClick={onClick}
            type={type ?? 'default'}
        >
            {submitProp || <span>Proceed</span>}
        </Button>
    );
};


const CustomFormButton = ({
    form,
    submitProp,
    className,
    otherDisable,
    width,
    type,
    setDisabled,
    onClick,
}: {
    form: FormInstance;
    submitProp?: any;
    className?: any;
    otherDisable?: boolean;
    width?: any;
    type: ButtonType;
    setDisabled?: (e: boolean) => void;
    onClick: (e?:any) => void;
}) => {
    const [submittable, setSubmittable] = React.useState(false);

    // Watch all values
    const values = Form.useWatch([], form);

    React.useEffect(() => {
        form.validateFields({ validateOnly: true }).then(
            () => {
                // console.log('Yes');
                setSubmittable(true);
                setDisabled?.(true);
            },
            () => {
                // console.log('No');
                setSubmittable(false);
                setDisabled?.(false);
            },
        );
    }, [values, form, setDisabled]);

    const [windowSize, setWindowSize] = useState<{
        width: number;
        height: number;
    }>({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    const handleResize = () => {
        setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight,
        });
    };

    useEffect(() => {
        setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight,
        });
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <Button
            htmlType="button"
            type={type ?? 'default'}
            disabled={!submittable || (!isEmpty(otherDisable) && !otherDisable)}
            className={`${className} ${width || (windowSize.width > 540 ? 'w-350px' : 'w-250px')} ${
                !submittable || (!isEmpty(otherDisable) && !otherDisable) ? 'bg-gray-400' : ''
            }`}
            onClick={() => onClick?.()}
        >
            {submitProp || <span>Proceed</span>}
        </Button>
    );
};
export { CustomFormButton };