
export const OverviewIcon = () => (
    <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M13.8125 10.0625H9.5625V14.3125H13.8125V10.0625Z"
            stroke="#0866FF"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M7.4375 10.0625H3.1875V14.3125H7.4375V10.0625Z"
            stroke="#0866FF"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M13.8125 3.6875H9.5625V7.9375H13.8125V3.6875Z"
            stroke="#0866FF"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M7.4375 3.6875H3.1875V7.9375H7.4375V3.6875Z"
            stroke="#0866FF"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export const KycSetupIcon = () => (
    <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M1.09491 9.73955C0.968086 8.97504 0.968325 8.19483 1.09562 7.43039C1.87549 7.4488 2.57745 7.0748 2.85016 6.41676C3.12287 5.75801 2.89124 4.99797 2.32599 4.45964C2.77678 3.82872 3.32868 3.27658 3.95941 2.82551C4.49774 3.39076 5.25849 3.62239 5.91653 3.34968C6.57528 3.07697 6.94928 2.37501 6.93016 1.59514C7.69487 1.468 8.47532 1.468 9.24003 1.59514C9.22162 2.37572 9.59562 3.07697 10.2537 3.34968C10.9124 3.62239 11.6725 3.39076 12.2108 2.82551C12.8417 3.27631 13.3938 3.82821 13.8449 4.45893C13.2797 4.99726 13.048 5.75801 13.3207 6.41676C13.5935 7.07551 14.2954 7.44951 15.0753 7.42968C15.2025 8.19462 15.2025 8.97532 15.0753 9.74026C14.2947 9.72114 13.5935 10.0951 13.3207 10.7532C13.048 11.4119 13.2797 12.1727 13.8449 12.711C13.3941 13.3417 12.8422 13.8936 12.2115 14.3444C11.6732 13.7792 10.9124 13.5476 10.2537 13.8203C9.59491 14.093 9.22091 14.7949 9.24074 15.5755C8.47576 15.7025 7.69506 15.7023 6.93016 15.5748C6.94928 14.7949 6.57457 14.093 5.91724 13.8203C5.25849 13.5476 4.49774 13.7792 3.95941 14.3444C3.32875 13.8936 2.77685 13.3417 2.32599 12.711C2.89124 12.1727 3.12287 11.4119 2.85016 10.7539C2.57745 10.0951 1.87549 9.72043 1.09491 9.73955ZM8.08545 10.71C8.64903 10.71 9.18954 10.4861 9.58805 10.0876C9.98657 9.68906 10.2105 9.14856 10.2105 8.58497C10.2105 8.02139 9.98657 7.48088 9.58805 7.08237C9.18954 6.68385 8.64903 6.45997 8.08545 6.45997C7.52186 6.45997 6.98136 6.68385 6.58285 7.08237C6.18433 7.48088 5.96045 8.02139 5.96045 8.58497C5.96045 9.14856 6.18433 9.68906 6.58285 10.0876C6.98136 10.4861 7.52186 10.71 8.08545 10.71Z"
            fill="#0866FF"
        />
    </svg>
);


export const IdPortalIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="20" viewBox="0 0 19 20" fill="none">
  <path d="M13.2371 15.4874C13.5055 15.6097 13.8392 15.9267 14.0012 16.1825C14.0549 16.5395 14.3233 15.1602 15.6368 14.3426M17.9998 15.1007C17.9998 16.0023 17.6227 16.8669 16.9516 17.5045C16.2804 18.142 15.37 18.5002 14.4208 18.5002C13.4716 18.5002 12.5613 18.142 11.8901 17.5045C11.2189 16.8669 10.8418 16.0023 10.8418 15.1007C10.8418 14.1991 11.2189 13.3344 11.8901 12.6969C12.5613 12.0593 13.4716 11.7012 14.4208 11.7012C15.37 11.7012 16.2804 12.0593 16.9516 12.6969C17.6227 13.3344 17.9998 14.1991 17.9998 15.1007Z" stroke="#0866FF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M8.61226 11.7012C9.60058 11.7012 10.4018 10.9402 10.4018 10.0015C10.4018 9.06276 9.60058 8.30176 8.61226 8.30176C7.62394 8.30176 6.82275 9.06276 6.82275 10.0015C6.82275 10.9402 7.62394 11.7012 8.61226 11.7012Z" stroke="#0866FF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M5.48061 15.1005C6.33063 14.3356 7.04643 13.9107 8.63462 13.8257M16.2177 9.57634C16.2177 8.89644 16.24 6.81425 16.1282 5.96438C16.0611 5.26324 15.8598 4.30713 14.9426 3.75471C14.3834 3.4785 13.8466 3.22354 11.2741 3.20229M5.88325 3.20229C3.95953 3.20229 2.46082 3.35102 1.72265 4.22214C1.09632 5.03547 1.13301 5.96438 1.07395 6.26184C0.939741 7.85535 1.02922 13.422 1.02922 14.3569C1.02922 15.3342 0.96211 17.3204 2.37135 17.9901C3.57926 18.5637 4.83192 18.4788 9.082 18.5M8.47804 1.50255C7.94118 1.50255 7.49381 1.50255 7.04643 1.94873C6.66616 2.28868 6.72432 2.58869 6.57669 3.11731C6.36731 3.86095 6.26352 4.25359 6.55432 4.56209C6.82095 4.89354 7.26923 4.89524 7.6656 4.89609H7.67276C8.05303 4.92159 9.33074 4.90969 9.70832 4.89609C10.1128 4.88079 10.4393 4.85955 10.7149 4.5196C10.961 4.21619 10.8151 3.71052 10.6702 3.18105C10.527 2.65923 10.5807 2.39492 10.2004 1.94873C9.66358 1.43881 9.01489 1.50255 8.47804 1.50255Z" stroke="#0866FF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
);
