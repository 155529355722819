import axios, { AxiosRequestConfig } from 'axios';
import { baseUrl } from './baseUrl';


// Get the token from localStorage or set it to null if not found
const token: string | null = localStorage.getItem('token');

// Create a base configuration object with the base URL
const baseConfig: AxiosRequestConfig = {
  baseURL: baseUrl,
  headers: { "Content-Type": "application/json" },
};

// Create an Axios instance with the base configuration
const axiosService = axios.create(baseConfig);

// Check if a token exists and set the Authorization header
if (token) {
  axiosService.defaults.headers.common.Authorization = `Bearer ${token}`;
}

// Add a response interceptor for error handling and logging
axiosService.interceptors.response.use(
  (response) => 
    // Do something with the response data, if needed
     response
  ,
  (error) => 
    // Handle response errors, log them, and reject the promise
    
     Promise.reject(error)
  ,
);

// Export the configured Axios instance
export { axiosService };

