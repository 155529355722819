/* eslint-disable jsx-a11y/control-has-associated-label */
import { useLocation, useNavigate } from 'react-router-dom';
// import { logo, rightLayout2 } from "../../../../assets/images";
import { Dropdown, MenuProps } from 'antd';
import moment from 'moment';
import { checkIsHome } from 'utils';
import { dummyHead, defaultLogo } from '../../../../../assets/images';
import './AuthHeader.scss'

interface AuthHeaderProps {
    showMenu?: () => void;
    dashboardSidebar: boolean;
    noRole?: boolean
}

const AuthHeader: React.FC<AuthHeaderProps> = ({ showMenu, dashboardSidebar, noRole }) => {
    const navigate = useNavigate();

    const { pathname } = useLocation();

    const isHome = checkIsHome(pathname, window.location.hash);

    const toggleMenu = () => {
        showMenu?.();
    };

    const items: MenuProps['items'] = [
        {
            key: '1',
            label: (
                <div>
                    <span className="text-gray-600">Logout</span>
                </div>
            ),
        },
    ];

    return (
        <div className="auth-layout-header">
            <nav className="auth-nav" id="auth-nav">
                <div className="auth-nav-center">
                    {/*  Auth Nav header */}
                    <div className="auth-nav-header">
                        <div className="logo-container cursor-pointer" onClick={() => !noRole && navigate('/')} aria-hidden>
                            <img src={defaultLogo} className="auth-nav-logo" alt="" />
                            <div className="d-none d-lg-block">
                                <span className="fs-9 text-gray-600">
                                    {moment(new Date()).format('dddd Do MMMM YYYY h:mm a')}
                                </span>
                            </div>
                        </div>

                        <div className="d-lg-none">
                            <button
                                className={`hamburger-button ${dashboardSidebar ? 'open' : ''}`}
                                onClick={toggleMenu}
                                type="button"
                            >
                                <div className="bar" />
                                <div className="bar" />
                                <div className="bar" />
                            </button>
                        </div>

                        {!noRole && (
                            <>
                                {' '}
                                <div className="d-none d-lg-flex headerLeftNav">
                                    <div>
                                        <svg
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M8.99952 17.9999V18.7499C8.99952 19.5456 9.31559 20.3087 9.8782 20.8713C10.4408 21.4339 11.2039 21.7499 11.9995 21.7499C12.7952 21.7499 13.5582 21.4339 14.1208 20.8713C14.6834 20.3087 14.9995 19.5456 14.9995 18.7499V17.9999M5.26855 9.74994C5.26731 8.86045 5.44213 7.97951 5.78294 7.1579C6.12375 6.33628 6.62381 5.59025 7.25429 4.9628C7.88476 4.33535 8.6332 3.83889 9.45644 3.50204C10.2797 3.16519 11.1615 2.99461 12.0509 3.00013C15.7625 3.02772 18.7312 6.11275 18.7312 9.83468V10.4999C18.7312 13.8577 19.4337 15.8061 20.0524 16.871C20.1191 16.9848 20.1546 17.1142 20.1553 17.246C20.156 17.3779 20.1219 17.5076 20.0565 17.6221C19.991 17.7366 19.8966 17.8318 19.7826 17.8982C19.6686 17.9645 19.5392 17.9996 19.4073 17.9999H4.59173C4.45985 17.9996 4.33038 17.9645 4.2164 17.8981C4.10242 17.8318 4.00795 17.7366 3.94252 17.622C3.8771 17.5075 3.84303 17.3778 3.84376 17.2459C3.84449 17.114 3.87999 16.9846 3.94667 16.8709C4.56573 15.8059 5.26855 13.8575 5.26855 10.4999L5.26855 9.74994Z"
                                                stroke="#3B4EE4"
                                                strokeWidth="1.5"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                        </svg>
                                    </div>

                                    <div className="headerUsernameRole">
                                        <div>
                                            <span className="fs-8 text-gray-800 fw-bold">Hi, Demilade</span>
                                        </div>
                                        <div className="role text-center">
                                            <span className="role-text">Admin</span>
                                        </div>
                                    </div>
                                    <div className="headerUserAvatar">
                                        <div className="userImg">
                                            <img src={dummyHead} alt="" className="userImg" />
                                        </div>

                                        <Dropdown menu={{ items }}>
                                            <button
                                                onClick={(e) => e.preventDefault()}
                                                type="button"
                                                className="btn-clear"
                                            >
                                                <svg
                                                    width="18"
                                                    height="18"
                                                    viewBox="0 0 18 18"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M14.625 6.75L9 12.375L3.375 6.75"
                                                        stroke="#1F2937"
                                                        strokeWidth="1.5"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />
                                                </svg>
                                            </button>
                                        </Dropdown>
                                    </div>
                                </div>{' '}
                            </>
                        )}
                    </div>
                </div>
            </nav>
        </div>
    );
};

export { AuthHeader };
