import React, { useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { Footer } from './component/Footer';
import { Header } from './component/Header';
import { AuthHeader } from './component/authenticated/AuthHeader';
import SideBar from './component/authenticated/SideBar';

type Props = {
    children?: any;
};

const AuthenticatedLayout: React.FC<Props> = ({ children }) => {
    const location = useLocation();

    React.useEffect(() => {
        if (location.hash === '') {
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        }
    }, [location]);

    const [dashboardSidebar, setDashboardSidebar] = useState<boolean>(false);

    function showMenu() {
        setDashboardSidebar(!dashboardSidebar);
    }

    return (
        <div className="mainDashboard">
            <header>
                <AuthHeader showMenu={showMenu} dashboardSidebar={dashboardSidebar} />
            </header>

            <section className='sideBar-Outlet'>
                <div className='dashboardSidebar'>
                    <SideBar dashboardSidebar={dashboardSidebar} showMenu={showMenu} />
                </div>
                <div className="main-container">
                    <Outlet />
                </div>
            </section>
        </div>
    );
};

export { AuthenticatedLayout };
