import { FlapperSpinner } from 'react-spinners-kit';

import './loading.scss';
// import variables from "assets/styles/components/_export.module.scss";

export const Loader = () => (
    <div className="loader-container">
        <FlapperSpinner color="blue" />
    </div>
);

export const LoaderContainer = () => (
    <div className="loading-container">
        <FlapperSpinner color="blue" />
    </div>
);
