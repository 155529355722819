import { Progress, Upload } from 'antd';
import React, { useState } from 'react';
import axios from 'axios';
import { ErrorToast } from 'components/src/toast';
import { CloudSvg } from './CloudSvg';

interface FileUploadProps {
    fileName?: string;
    //onUpload?: string;
    setFileLink: (e: string) => void;
    accepted?: string;
}

export const FileUpload: React.FC<FileUploadProps> = ({ fileName, setFileLink, accepted }) => {
    const [progress, setProgress] = useState<number>(0);

    const uploadImage = async (options: any) => {
        const { onSuccess, onError, file, onProgress } = options;

        const fmData = new FormData();
        const config:any = {
            headers: { 'content-type': 'multipart/form-data' },
            onUploadProgress: (event: { loaded: number; total: number; }) => {
                const percent = Math.floor((event.loaded / event.total) * 100);
                setProgress(percent);
                if (percent === 100) {
                    setTimeout(() => setProgress(0), 1000);
                }
                onProgress({ percent: (event.loaded / event.total) * 100 });
            },
        };
        fmData.append('documents[]', file);
        fmData.append('names[]', fileName || 'dummyImage');
        try {
            const res = await axios.post(
                'https://bucket.voxepay.app/upload/kyc.php?api_key=ff837bbgw@4gpl67',
                fmData,
                config,
            );

            onSuccess('Ok');
            setFileLink(res.data.fileLinks[0]);
        } catch (err) {
            //const error = new Error("Some error");
            ErrorToast(err);
            onError({ err });
        }
    };
    return (
        <div className="d-flex flex-column w-100">
            <Upload
                name="logo"
                listType="text"
                className="w-100"
                multiple={false}
                customRequest={(e:any) => uploadImage(e)}
                accept={accepted}
                onRemove={() => setFileLink('')}
                maxCount={1}
            >
                <div className="form-control fileUpload fs-8 d-flex align-items-center justify-content-between">
                    <div>Click here to upload document</div>
                    <CloudSvg />
                </div>
            </Upload>
            {/* 
    {progress > 0 ? <Progress percent={progress} className="mt-10" /> : null} */}
        </div>
    );
};
