import { AppLayout } from 'components/src/layout/AppLayout';
import { FC, Suspense, lazy } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import User from 'pages/User';
import { NoSideBarLayout } from 'components/src/layout/NoSideBarLayout';
import { AuthenticatedLayout } from '../components/src/layout/AuthenticatedLayout';
import { Error404 } from './component/Error404';
import { FallbackView } from './component/FallbackView';




const AppRouter: FC = () => {
    const Dashboard = lazy(() => import('pages/Dashboard'));
    const KycSetup = lazy(() => import('pages/KycSetup'));
    const CreateKycSetup = lazy(() => import('pages/KycSetup/Create'));
    const EditKycSetup = lazy(() => import('pages/KycSetup/Edit'));
    const IDPortal = lazy(() => import('pages/IDPortal'));
    const OverviewTables = lazy(() => import('pages/Dashboard/OverviewTables'));
    const OverviewDetails = lazy(() => import('pages/Dashboard/Details'));
    return (
        <Suspense fallback={<FallbackView />}>
            <Routes>
                <Route element={<AppLayout />}>
                    {/* <Route element={<MainLayout />}>
                        <Route path="/" element={<LandingPage />} />
                    </Route>
                    <Route path="/signIn" element={<Login />} />
                    <Route path="/signUp" element={<Registration />} /> */}
                    <Route element={<AuthenticatedLayout />}>
                        <Route path="/" element={<Navigate to="/dashboard" replace />} />
                        <Route path="/dashboard" element={<Dashboard />} />
                        <Route path="/dashboard/:id" element={<OverviewTables />} />
                        <Route path="/dashboard/details/:id" element={<OverviewDetails />} />

                        <Route path="kycSetup">
                            <Route path="" element={<KycSetup />} />
                            <Route path="create" element={<CreateKycSetup />} />
                            <Route path="edit/:id" element={<EditKycSetup />} />
                        </Route>
                        <Route path="idPortal">
                            <Route path="" element={<IDPortal />} />
                        </Route>
                        
                    </Route>
                    <Route element={<NoSideBarLayout />}>
                        <Route path="kycManagement">
                            <Route path="" element={<User />} />
                            <Route path=":id" element={<User />} />
                        </Route>
                    </Route>

                    <Route path="*" element={<Error404 />} />
                </Route>
            </Routes>
        </Suspense>
    );
};

export { AppRouter };
