/* eslint-disable jsx-a11y/label-has-associated-control */
import { DatePicker, Form, Input, Select,Button } from 'antd';
import { CustomButton, LoaderContainer, SubmitButton } from 'components';
import { SuccessToast } from 'components/src/toast';
import moment from 'moment';
import { FileUpload } from 'pages/KycSetup/Components/FileUpload';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useGetByEntityCode, useGetEntityById, useGetFieldType, useSaveForm } from 'services/constants/kycSetupService';
import { camelCaseToTitleCase, getFieldType } from 'utils';
import './User.scss';
import { Modal } from 'react-bootstrap';
import { convertToFormStructure } from './component/ReUseableFn';

const User: React.FC = () => {
    const { id } = useParams();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const [entityId] = searchParams.getAll('id');
    const [entityCode] = searchParams.getAll('code');
    const [entityName] = searchParams.getAll('registeredName');

    const { data: entityList, mutate: mutateEntity, isLoading: loadingEntity } = useGetEntityById();
    const { data: fieldTypeList, mutate: mutateFieldType, isLoading: loadingFieldType, isError } = useGetFieldType();
    const { mutate: mutateSave, isLoading: loadingForm } = useSaveForm();
    const { data: dataEntityCode, mutate: mutateEntityCode, isLoading: loadingEntityCode } = useGetByEntityCode();
    
    const [successModal, setSuccessModal] = useState<boolean>(false);

    useEffect(() => {
        mutateEntity(id);
        mutateFieldType();
        if (entityCode) mutateEntityCode(entityCode);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const numberOfForms = 10; // Dynamically set the number of forms based on formData length
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const formInstances = Array.from({ length: numberOfForms }, () => Form.useForm()[0]); // Create form instances

    const [selectedSection, setSelectedSection] = useState<number>(1);

    const [fieldSectionValue, setFieldSectionValue] = useState<any>({}); // State to hold form values

    const onFinish = (values: any, index: number) => {
        setFieldSectionValue((prev: any) => ({ ...prev, [`form${index + 1}`]: values }));
        SuccessToast('Saved');
    };

    const [showPreview, setShowPreview] = useState<boolean>(false);

    const renderForm = (form: any, index: number) =>
        selectedSection === index + 1 ? (
            <Form
                key={index}
                name={`form${index + 1}`}
                initialValues={{ show: false, ...fieldSectionValue[`form${index + 1}`] }}
                layout="vertical"
                onFinish={(values) => onFinish(values, index)}
                autoComplete="off"
                form={form}
            >
                <div className="py-10 px-8">
                    <h3 className="text-navy-blue fs-5">{entityList?.sections[index].name}</h3>
                    <h6 className="text-gray-500 fs-8">{entityList?.sections[index]?.description}</h6>
                </div>
                <div className="input-grid">
                    {entityList?.sections[index].fields.map((item: any) => (
                        <div key={item.id}>
                            <label className="required fw-bold fs-base text-dark my-1">{item.name}</label>
                            <Form.Item
                                name={item.name}
                                rules={[{ required: item.required, message: `Enter ${item.name}` }]}
                            >
                                {getFieldType(item.fieldTypeId, fieldTypeList) === 'text' && <Input size="large" />}
                                {getFieldType(item.fieldTypeId, fieldTypeList) === 'date' && (
                                    <DatePicker format="YYYY/MM/DD" />
                                )}
                                {getFieldType(item.fieldTypeId, fieldTypeList) === 'select' && (
                                    <Select
                                        className="bg-white"
                                        dropdownStyle={{ zIndex: 100000 }}
                                        options={item.options?.map((option: string) => ({
                                            label: option,
                                            value: option.toLowerCase(),
                                        }))}
                                        allowClear
                                        style={{ width: '100%' }}
                                    />
                                )}
                                {(getFieldType(item.fieldTypeId, fieldTypeList) === 'tel' ||
                                    getFieldType(item.fieldTypeId, fieldTypeList) === 'number') && (
                                    <Input type={getFieldType(item.fieldTypeId, fieldTypeList)} size="large" />
                                )}

                                {getFieldType(item.fieldTypeId, fieldTypeList) === 'file' && (
                                    <FileUpload
                                        setFileLink={(e: string) => form.setFieldValue(item.name, e)}
                                        accepted=".pdf,.jpg,.png"
                                    />
                                )}
                            </Form.Item>
                        </div>
                    ))}
                </div>
                <div className="d-flex justify-content-center py-5">
                    <Form.Item>
                        <SubmitButton submitProp="Save" className="bg-default-color" type="primary" form={form} />
                    </Form.Item>
                </div>
            </Form>
        ) : null;

    const saveForm = () => {
        const payload = {
            entityName,
            entityCode: entityCode || entityId,
            entityTypeId: id,
            submittedSections: Object.keys(fieldSectionValue ?? {}).map((formKey, index) => ({
                sectionName: entityList?.sections[index].name,
                fieldValuesList: Object.keys(fieldSectionValue[formKey]).map((key) => ({
                    fieldName: key,
                    value: fieldSectionValue[formKey][key],
                })),
            })),
        };
        mutateSave(payload, {
            onSuccess: () => {
                SuccessToast('Kyc submitted successfully ');
                setSuccessModal(true)
            },
        });
    };

    useEffect(() => {
        if (dataEntityCode) {
            setFieldSectionValue(convertToFormStructure(dataEntityCode));
            setShowPreview(true);
        }
    }, [dataEntityCode]);

    const loading = loadingEntity || loadingForm || loadingFieldType || loadingEntityCode;
    return (
        <div className="bg-form-bg">
            <div className="py-5 text-center">
                <h1 className="text-navy-blue fs-5">KYC PROCESS</h1>
                <span className="text-dark">Developed by SIMPLIFY SYNERGY</span>
            </div>

            {loading && <LoaderContainer />}

            {!loading &&
                !isError &&
                (!showPreview ? (
                    <div className="user-kyc-form-container">
                        <div className="section-container">
                            {entityList?.sections?.map((item: any, index: any) => (
                                <div
                                    key={index}
                                    className={`sideBar-section cursor-pointer ${
                                        selectedSection === index + 1 ? 'active' : ''
                                    }`}
                                    onClick={() => setSelectedSection(index + 1)}
                                    aria-hidden
                                >
                                    <span>{item.name}</span>
                                </div>
                            ))}
                            <div
                                className="sideBar-section cursor-pointer"
                                onClick={() => setShowPreview(true)}
                                aria-hidden
                            >
                                <span>Preview</span>
                            </div>
                        </div>

                        <div className="bg-white form-container">
                            {formInstances?.map((form: any, index: number) => renderForm(form, index))}
                        </div>
                    </div>
                ) : (
                    <div className="previewContainer">
                        <div className="pt-10">
                            {!dataEntityCode ? (
                                <>
                                    <div className="d-flex">
                                        {!dataEntityCode && (
                                            <div
                                                onClick={() => {
                                                    setSelectedSection(1);
                                                    setShowPreview(false);
                                                }}
                                                aria-hidden
                                                className="cursor-pointer"
                                            >
                                                <svg
                                                    width="25"
                                                    height="24"
                                                    viewBox="0 0 25 24"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M11.4016 15.7123C11.6945 16.0052 12.1694 16.0052 12.4623 15.7123C12.7552 15.4194 12.7552 14.9445 12.4623 14.6516L11.4016 15.7123ZM8.75 12L8.21967 11.4697C7.92678 11.7626 7.92678 12.2374 8.21967 12.5303L8.75 12ZM12.4623 9.34836C12.7552 9.05547 12.7552 8.58059 12.4623 8.2877C12.1694 7.99481 11.6945 7.99481 11.4016 8.2877L12.4623 9.34836ZM16.25 12.75C16.6642 12.75 17 12.4142 17 12C17 11.5858 16.6642 11.25 16.25 11.25V12.75ZM20.75 12C20.75 16.5563 17.0563 20.25 12.5 20.25V21.75C17.8848 21.75 22.25 17.3848 22.25 12H20.75ZM12.5 20.25C7.94365 20.25 4.25 16.5563 4.25 12H2.75C2.75 17.3848 7.11522 21.75 12.5 21.75V20.25ZM4.25 12C4.25 7.44365 7.94365 3.75 12.5 3.75V2.25C7.11522 2.25 2.75 6.61522 2.75 12H4.25ZM12.5 3.75C17.0563 3.75 20.75 7.44365 20.75 12H22.25C22.25 6.61522 17.8848 2.25 12.5 2.25V3.75ZM12.4623 14.6516L9.28033 11.4697L8.21967 12.5303L11.4016 15.7123L12.4623 14.6516ZM9.28033 12.5303L12.4623 9.34836L11.4016 8.2877L8.21967 11.4697L9.28033 12.5303ZM8.75 12.75H16.25V11.25H8.75V12.75Z"
                                                        fill="#0866FF"
                                                    />
                                                </svg>
                                            </div>
                                        )}
                                        <h1 className="text-center text-navy-blue fs-5 w-100">PREVIEW</h1>
                                    </div>
                                    <span className="text-gray-500 fs-8">
                                        Please ensure all information and documents are accurate before you proceed to
                                        submit. Inaccurate information or document can delay the activation of your
                                        business
                                    </span>
                                </>
                            ) : (
                                <h1 className="text-center text-navy-blue fs-5 w-100">Request Submitted</h1>
                            )}
                        </div>

                        <div className="pb-10">
                            {Object.keys(fieldSectionValue ?? {}).map((formKey, index) => (
                                <div key={formKey} className="field-item">
                                    <div className="d-flex justify-content-between">
                                        <h1 className="text-navy-blue fs-base">{entityList?.sections[index].name}</h1>
                                        <div
                                            className={`d-flex justify-content-center align-items-center  ${
                                                dataEntityCode ? 'cursor-disabled' : 'cursor-pointer'
                                            }`}
                                            onClick={() => {
                                                if (!dataEntityCode) {
                                                    setSelectedSection(index + 1);
                                                    setShowPreview(false);
                                                }
                                            }}
                                            aria-hidden
                                        >
                                            {dataEntityCode &&
                                                (dataEntityCode.submittedSections[index].verified ? (
                                                    <span className="text-darkPastelGreen fs-8 ms-2">
                                                        <svg
                                                            width="25"
                                                            height="24"
                                                            viewBox="0 0 25 24"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                d="M12.5 2.25C10.5716 2.25 8.68657 2.82183 7.08319 3.89317C5.47982 4.96451 4.23013 6.48726 3.49218 8.26884C2.75422 10.0504 2.56114 12.0108 2.93735 13.9021C3.31355 15.7934 4.24215 17.5307 5.60571 18.8943C6.96928 20.2579 8.70656 21.1865 10.5979 21.5627C12.4892 21.9389 14.4496 21.7458 16.2312 21.0078C18.0127 20.2699 19.5355 19.0202 20.6068 17.4168C21.6782 15.8134 22.25 13.9284 22.25 12C22.2473 9.41498 21.2192 6.93661 19.3913 5.10872C17.5634 3.28084 15.085 2.25273 12.5 2.25ZM16.7806 10.2806L11.5306 15.5306C11.461 15.6004 11.3783 15.6557 11.2872 15.6934C11.1962 15.7312 11.0986 15.7506 11 15.7506C10.9014 15.7506 10.8038 15.7312 10.7128 15.6934C10.6218 15.6557 10.539 15.6004 10.4694 15.5306L8.21938 13.2806C8.07865 13.1399 7.99959 12.949 7.99959 12.75C7.99959 12.551 8.07865 12.3601 8.21938 12.2194C8.36011 12.0786 8.55098 11.9996 8.75 11.9996C8.94903 11.9996 9.1399 12.0786 9.28063 12.2194L11 13.9397L15.7194 9.21937C15.7891 9.14969 15.8718 9.09442 15.9628 9.0567C16.0539 9.01899 16.1515 8.99958 16.25 8.99958C16.3486 8.99958 16.4461 9.01899 16.5372 9.0567C16.6282 9.09442 16.7109 9.14969 16.7806 9.21937C16.8503 9.28906 16.9056 9.37178 16.9433 9.46283C16.981 9.55387 17.0004 9.65145 17.0004 9.75C17.0004 9.84855 16.981 9.94613 16.9433 10.0372C16.9056 10.1282 16.8503 10.2109 16.7806 10.2806Z"
                                                                fill="#09C63E"
                                                            />
                                                        </svg>
                                                        Verified
                                                    </span>
                                                ) : (
                                                    // <span className="text-danger fs-8 ms-2">Not Verified</span>
                                                    <div />
                                                ))}

                                            {!dataEntityCode && (
                                                <>
                                                    <svg
                                                        width="25"
                                                        height="24"
                                                        viewBox="0 0 25 24"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            d="M16.25 5.25L19.25 8.25M20.75 11.25V19.5C20.75 19.6989 20.671 19.8897 20.5303 20.0303C20.3897 20.171 20.1989 20.25 20 20.25H5C4.80109 20.25 4.61032 20.171 4.46967 20.0303C4.32902 19.8897 4.25 19.6989 4.25 19.5V4.5C4.25 4.30109 4.32902 4.11032 4.46967 3.96967C4.61032 3.82902 4.80109 3.75 5 3.75H13.25M12.5 15H9.5V12L18.5 3L21.5 6L12.5 15Z"
                                                            stroke={dataEntityCode ? '#d1d5db' : '#0866FF'}
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                    </svg>
                                                    <span
                                                        className={`${
                                                            dataEntityCode ? 'text-gray-300' : ' text-primary'
                                                        }  fs-8 ms-2`}
                                                    >
                                                        Edit
                                                    </span>
                                                </>
                                            )}
                                        </div>
                                    </div>

                                    {Object.keys(fieldSectionValue[formKey]).map((key) => (
                                        <div key={key}>
                                            <div className="d-flex justify-content-between">
                                                <span className="text-dark2 fs-8 fw-bolder text-start min-w-25">
                                                    {camelCaseToTitleCase(key)}
                                                </span>
                                                <span className="text-dark fs-8 fw-bold text-end">
                                                    {key === 'dob'
                                                        ? moment(fieldSectionValue[formKey][key]).format('YYYY-MM-DD')
                                                        : fieldSectionValue[formKey][key]}
                                                </span>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            ))}

                            {!dataEntityCode && (
                                <div className="d-flex justify-content-center py-10">
                                    <Form.Item>
                                        <CustomButton
                                            submitProp="Submit"
                                            className="bg-default-color"
                                            type="primary"
                                            onClick={() => saveForm()}
                                            disabled={
                                                Object.keys(fieldSectionValue).length !== entityList?.sections?.length
                                            }
                                        />
                                    </Form.Item>
                                </div>
                            )}
                        </div>
                    </div>
                ))}

            {successModal && (
                <Modal
                    dialogClassName="m-auto px-5 modal-card"
                    aria-hidden="false"
                    show={successModal}
                    onHide={() => setSuccessModal(false)}
                    animation
                    centered
                    size="lg"
                    backdrop="static"
                    style={{ zIndex: 10000 }}
                >
                    <Modal.Body className="rounded">
                        <div className="m-5 d-flex flex-column justify-content-center align-items-center">
                            <div className="my-3 text-center fw-bold">
                                <h1 className="p-0 text-dark mb-5 fs-5">Confirmation</h1>

                                <h1 className="p-0 text-gray-500 fs-base">Form successfully saved</h1>
                            </div>

                            <div className=" d-flex flex-column  justify-align-center gap-5">
                                <Button
                                    type="primary"
                                    htmlType="button"
                                    className="d-flex justify-content-center align-items-center w-150px"
                                    onClick={() => navigate(0)}
                                >
                                    Ok
                                </Button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            )}
        </div>
    );
};

export default User;
