import React from 'react';
import { useNavigate } from "react-router-dom";
import './BreadCrumb.scss'

export const Breadcrumb = ({
  links,
  onBack,
}: {
  links: any[];
  onBack?: () => void;
}) => {
  const navigate = useNavigate();
  return (
      <div className="breadCrumb-component">
          <svg
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className='cursor-pointer'
              onClick={() => (onBack ? onBack() : navigate(-1))}
          >
              <g clipPath="url(#clip0_634_9664)">
                  <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M18 11.8125H9.63312L13.4763 7.96937L12.5 7L7 12.5L12.5 18L13.4694 17.0306L9.63312 13.1875H18V11.8125Z"
                      fill="#0866FF"
                  />
              </g>
              <rect x="0.5" y="1" width="23" height="23" rx="11.5" stroke="#0866FF" />
              <defs>
                  <clipPath id="clip0_634_9664">
                      <rect y="0.5" width="24" height="24" rx="12" fill="white" />
                  </clipPath>
              </defs>
          </svg>

          {links.map((link, index) => {
              let showIcon = true;

              if (links.length >= 1) {
                  if (index + 1 === links.length) {
                      showIcon = false;
                  }
              }

              return (
                  <div key={index} className="d-flex align-items-center gap-4">
                      <div
                          style={{ cursor: 'pointer', paddingRight: '10px' }}
                          onClick={() => navigate(link.path)}
                          aria-hidden
                      >
                          <span className={`fs-base fw-bolder ${showIcon ? 'text-navy-blue-500' : 'text-navy-blue'}`}>{link.name}</span>
                      </div>

                      {showIcon && (
                          <svg
                              width="10"
                              height="13"
                              viewBox="0 0 10 13"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                          >
                              <path
                                  d="M0 1.5744L0 11.4256C0 11.8254 0.236844 12.192 0.613658 12.3774C0.991157 12.5628 1.44706 12.5363 1.79669 12.3087L9.55942 7.25661C9.83645 7.07665 10.0007 6.78017 10 6.46438C9.99858 6.14825 9.83207 5.85271 9.55431 5.6742L1.79158 0.68791C1.44044 0.462617 0.986688 0.438175 0.610557 0.623958C0.235339 0.809997 0 1.1757 0 1.5744Z"
                                  fill="#0866FF"
                              />
                          </svg>
                      )}
                  </div>
              );
          })}
      </div>
  );
};

