export const convertToFormStructure = (data: { submittedSections: any[] }) => {
    const result: any = {};
    data?.submittedSections.forEach((section: { fieldValuesList: any[] }, index: number) => {
        const formKey: any = `form${index + 1}`;
        result[formKey] = {};
        section.fieldValuesList.forEach((field: { fieldName: string | number; value: any }) => {
            result[formKey][field.fieldName] = field.value;
        });
    });
    return result;
};
