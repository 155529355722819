import React, { useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { Footer } from './component/Footer';
import { Header } from './component/Header';
import { AuthHeader } from './component/authenticated/AuthHeader';
import SideBar from './component/authenticated/SideBar';

type Props = {
    children?: any;
};

const NoSideBarLayout: React.FC<Props> = ({ children }) => {
    const location = useLocation();

    React.useEffect(() => {
        if (location.hash === '') {
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        }
    }, [location]);

  


    return (
        <div className="mainDashboard">
            <header>
                <AuthHeader dashboardSidebar={false} noRole/>
            </header>

            <section className='NoSideBar-Outlet'>
        
                <div className="main-container">
                    <Outlet />
                </div>
            </section>
        </div>
    );
};

export { NoSideBarLayout };
